import Snake from 'snake-game-react';
import React, { useState, useEffect } from 'react';
import "./Snake.css";

function SnakeGame(props) {
  useEffect(() => {
    const interval = setInterval(() => {
      if (document.getElementsByClassName("point-bar")[0].firstChild.textContent.includes("21")) {
        window.location.replace("/snake-highscore");
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return <div style={{ height: "100%", backgroundColor: "#F179AF" }}>
    {!props.result ? <div style={{ paddingTop: 40, backgroundColor: "#F179AF" }}>
      <Snake
        color1="#248ec2"
        color2="#1d355e"
        backgroundColor="#ebebeb"
      />
      <p style={{textAlign: "center"}} >Highscore : 20</p></div>
      : <div style={{ height: "100%" }}><div style={{ height: "100%", backgroundColor: "#03fcca", width: "20%", float: "left", display: "block" }}></div>
        <div style={{ height: "100%", backgroundColor: "#ffa100", width: "20%", float: "left", display: "block" }}></div>
        <div style={{ height: "100%", backgroundColor: "#545885", width: "20%", float: "left", display: "block" }}></div>
        <div style={{ height: "100%", backgroundColor: "#428029", width: "20%", float: "left", display: "block" }}></div>
        <div style={{ height: "100%", backgroundColor: "#7a1091", width: "20%", float: "left", display: "block" }}></div></div>
    }
  </div>

}

export default SnakeGame;