import React, {useEffect} from 'react';
import { CheckOutlined, CloseOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Avatar, Card, Divider, Tag, Popconfirm, Collapse } from 'antd';
import { db, update } from "./firebase";
import { ref } from "firebase/database";
import Moment from 'react-moment';
import 'moment/locale/nl';
import dayjs from 'dayjs';
import moment from 'moment'
import {vCard} from './vcard.js';
const { Meta } = Card;
const { Panel } = Collapse;




function Event(props) {
    const date = new Date(props.booking?.startTime);
    var time =""
    if (date.getMinutes() < 9){
        time = date.getHours() + ":0" + date.getMinutes()}
    else{
        time = date.getHours() + ":" + date.getMinutes()}
    return (
    <Card
    style={{ width: '46%', margin: '2%', float: 'left' }}
    actions={
    !props.booking[props.user.uid]?.available?
    [
      <CheckOutlined style={{padding: "12px 0"}} key="check" onClick = {() => props.change(props.booking,{"available" : true})}/>,
      <CloseOutlined style={{padding: "12px 0"}} key="cancel" onClick = {() => props.change(props.booking,{"available" : false})}/>
    ] : props.booking.selected === props.user.uid?
    [<div style={{backgroundColor: "#95de64", width: "100%", height: "100%", padding: "12px 0"}}>
    <CheckOutlined key="check" style={{color : "white"}}/></div>] :
    [<div style={{backgroundColor: "#69b1ff", width: "100%", height: "100%", padding: "12px 0"}}>
    <ClockCircleOutlined key="check" style={{color : "white"}}/></div>]
    }
  >
    <Meta
      avatar={<Avatar>{props.booking?.participants.numbers.reduce((total, p) => total = total + p.number,0)}</Avatar>}
      title={time}
      description={props.booking?.productName}
    />
  </Card>)
 }
function LocationEvent(props) {
    const total = props.booking?.participants.numbers.reduce((total, p) => total = total + p.number,0);
    const date = new Date(props.booking?.startTime);
    var time =""
    if (date.getMinutes() < 9){
        time = date.getHours() + ":0" + date.getMinutes()}
    else{
        time = date.getHours() + ":" + date.getMinutes()}
    return (
    <Card
    style={{ width: '46%', margin: '2%', float: 'left' }}
  >
    <Meta
      title={time}
      description={props.booking?.productName}
    />
   {props.booking?.productName.includes("Missie") && <p style={{color:"red", fontWeight: 600, marginBottom: 0}}>DRANKJE EN HAPJE</p>}
    <Tag color="blue" style={{marginTop: 20}}>{props.booking?.title}</Tag>
     <Tag style={{marginTop: 20}}>{total} personen</Tag>
  </Card>)
 }

 function AdminEvent(props) {
    const showSelected = (key, value, booking, accept, cancel) =>{
    if (booking.selected === key)
    return <Popconfirm
    title=""
    description="Opnieuw toewijzen?"
    okText="Ja"
    cancelText="Nee"
    onConfirm= {() => cancel(booking)}
  >
  <a href="#undo"><Tag  style={{marginTop: 10}} color="green">{value["user-info"].name}</Tag></a>
  </Popconfirm>
        }
    const showList = (key, value, booking, accept, cancel, undo) =>{
    if(booking[key] && booking[key].available)
        return <Popconfirm
    title=""
    description="Begeleider toevoegen?"
    okText="Ja"
    cancelText="Nee"
    onConfirm= {() => accept(booking, key)}
  >
  <Tag style={{marginTop: 10}} color="blue">{value["user-info"].name}</Tag></Popconfirm>
  if(booking[key] && !booking[key].available)
        return <Popconfirm
    title=""
    description="Annuleren"
    okText="Ja"
    cancelText="Nee"
    onConfirm= {() => undo(booking, key)}
  >
  <Tag style={{marginTop: 10}} color="red">{value["user-info"].name}</Tag></Popconfirm>
    }
    const date = new Date(props.booking?.startTime);
    var time =""
    if (date.getMinutes() < 9){
        time = date.getHours() + ":0" + date.getMinutes()}
    else{
        time = date.getHours() + ":" + date.getMinutes()}
    const date_string = moment(props.booking?.startTime).format('DD-MM-YY');
    console.log(props.booking);
    var card = {
            "version": "4.0",
            "n": date_string+" "+time+";"+props.booking.productName+";;",
            "tel": [
                {"value": props.booking?.options? props.booking?.options.sort((a, b) => a.name > b.name ? 1 : -1).at(-1).value : "", "type": "cell"}
            ]
        }
    var link = vCard.export(card, "The Suspect", false)
    return (
    <Card
    style={{ width: '46%', margin: '2%', float: 'left', minHeight: 220}}>
    <Meta
      avatar={<Avatar>{props.booking?.participants.numbers.reduce((total, p) => total = total + p.number,0)}</Avatar>}
      title={time}
      description={props.booking?.productName}
    />
    <div style={{textAlign: "center"}}>
    <a download="thesuspect.vcf" href={link.href}><Tag style={{marginTop: 10}}>{ props.booking?.options? props.booking?.options.at(-1).value : ""}</Tag></a>

    {Object.entries(props.users).map( ([key, value]) => {
             if(props.user.uid !== key)
            return showSelected(key,value, props.booking, props.change, props.cancel)
            else return null
            })}
    {props.booking?.promotionName?.includes("ZEPPELIN") && <Tag color="red" style={{marginTop: 10}}>DRINKS ZEPPELIN</Tag> }
    { props.booking.productName.includes("Missie") ?
    <Collapse size="small" style={{marginTop: 10}}>
      <Panel header="Begeleiders" key="1">
        {Object.entries(props.users).map( ([key, value]) => {
             if(props.user.uid !== key)
            return showList(key,value, props.booking, props.change, props.cancel, props.undo)
            else return null
            })}
      </Panel>
    </Collapse>: <Tag color="green" style={{marginTop: 10}}>Zonder begeleiding</Tag>}

            </div>
  </Card>)
 }



function CalendarOverview(props) {
   const { user, users, events, userData } = props;
   useEffect(() => {}, [user]);

   function changeEvent(event, value){
    const path= "events/"+event.startTime.substring(0,7)+"/"+event.bookingNumber + "/" + user.uid;
    update(ref(db, path), value)
    console.log(event);
    }

    function selectBooker(event, userKey){
    const path= "events/"+event.startTime.substring(0,7)+"/"+event.bookingNumber ;
    update(ref(db, path), {selected: userKey})
    console.log(event);
    }
    function undoForBooker(event, userKey){
    const path= "events/"+event.startTime.substring(0,7)+"/"+event.bookingNumber + "/" + userKey;
    update(ref(db, path), {available: null})
    console.log(event);
    }

    function deselectBooker(event){
    const path= "events/"+event.startTime.substring(0,7)+"/"+event.bookingNumber;
    update(ref(db, path), {selected: null})
    console.log(event);
    }

    var sorted_events = [...events];
    sorted_events.sort((a,b) => a.startTime < b.startTime? -1 : 1);
    const rows = [];
    var date = "";
    console.log(userData);

    var count = 0;
    for (var i=0; i < sorted_events.length; i++) {
        if(userData["user-info"]?.type === "host"){
            if(dayjs(sorted_events[i].startTime) > dayjs().add(-1,'day') && sorted_events[i].productName.includes("Missie")){
            if (!sorted_events[i][user.uid] || sorted_events[i][user.uid]?.available){
                if(date !== sorted_events[i].startTime.substring(0,10)){
                    if(count ===0 && rows.length > 0) rows.pop();
                    count =0;
                    rows.push(<Divider><div style={{backgroundColor: "#ff0003", borderRadius: 5, color: "white", padding: 5}}><Moment locale="nl-NL" format="dddd DD MMM">{sorted_events[i].startTime}</Moment></div></Divider>)}
                if(!sorted_events[i].selected || sorted_events[i].selected === user.uid ){
                    count = count + 1;
                    date = sorted_events[i].startTime.substring(0,10);
                    rows.push(<Event user={user} booking={sorted_events[i]} info={userData[sorted_events[i].bookingNumber]} change={changeEvent}/>);
                    }
                }
        }}
        else if(userData["user-info"]?.type === "admin"){
        if (dayjs(sorted_events[i].startTime) > dayjs().add(-1,'day')){
          if(date !== sorted_events[i].startTime.substring(0,10)){
            rows.push(<Divider><div style={{backgroundColor: "#ff0003", borderRadius: 5, color: "white", padding: 5}}><Moment locale="nl-NL" format="dddd DD MMM">{sorted_events[i].startTime}</Moment></div></Divider>)}
            date = sorted_events[i].startTime.substring(0,10);
            rows.push(<AdminEvent users={users} user={user} booking={sorted_events[i]} info={userData[sorted_events[i].bookingNumber]} cancel={deselectBooker} change={selectBooker} undo={undoForBooker} />);
            }}
        else{
        const games = [];
        console.log(sorted_events[i], sorted_events[i].promotionName)
          if (dayjs(sorted_events[i].startTime) > dayjs().add(-1,'day') ){
            if(date !== sorted_events[i].startTime.substring(0,10)){
                if(count === 0 && rows.length > 0) rows.pop();
                count =0;
                rows.push(<Divider><div style={{backgroundColor: "#ff0003", borderRadius: 5, color: "white", padding: 5}}><Moment locale="nl-NL" format="dddd DD MMM">{sorted_events[i].startTime}</Moment></div></Divider>)}
                count = count + 1;
                date = sorted_events[i].startTime.substring(0,10);
                rows.push(<LocationEvent user={user} booking={sorted_events[i]} info={userData[sorted_events[i].bookingNumber]}/>);}


          }
        }


    return <div>
    {rows}
    <Divider/>
    <div style={{display:"block", height: 50}}></div>
    </div>

  }

export default CalendarOverview;