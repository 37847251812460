import React from "react";
import {Timeline, Tag} from 'antd';


function Admin(props) {
  const { user } = props;
  console.log(user["user-info"]?.type);
  return <div>
  <p></p>
  <Timeline mode={"left"} style={{marginLeft: -100, overflow: "hidden", padding:100}}>
        <Timeline.Item label="MAANDAG"><Tag>...</Tag></Timeline.Item>
        <Timeline.Item label="DINSDAG"><Tag>...</Tag></Timeline.Item>
        <Timeline.Item label="WOENSDAG"><Tag>...</Tag></Timeline.Item>
        <Timeline.Item label="DONDERDAG"><Tag>...</Tag></Timeline.Item>
         <Timeline.Item label="VRIJDAG"><Tag>...</Tag></Timeline.Item>
          <Timeline.Item label="ZATERDAG"><b>11u-18u:</b> <Tag>...</Tag><br/><br/><b>19u-23u:</b> <Tag>...</Tag></Timeline.Item>
          <Timeline.Item label="ZONDAG"><b>11u-18u:</b> <Tag>...</Tag><br/><br/><b>19u-23u:</b> <Tag>...</Tag></Timeline.Item>
      </Timeline>
  </div>}

export default Admin;
