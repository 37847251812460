import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, signInWithGoogle } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import GoogleButton from 'react-google-button';

function Login() {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/list");
  }, [user, loading, navigate]);
  return (
    <div className="login">
      <div className="login__container" style={{margin: "auto", display: "flex", flexDirection: "column",
            alignItems: "center",
            justifyContent: "center", marginTop: "50%"}}>
        <GoogleButton className="login__btn login__google" onClick={signInWithGoogle}>
          Login with Google
        </GoogleButton>
      </div>
    </div>
  );
}
export default Login;
