
function Tijdsmachine(props){
    if (props.hint === 1)
    return <div style={{backgroundColor: "black", height: "100%",
    overflow: "hidden", color: "white", fontFamily: "Special Elite", fontSize: 24}}>
    <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
    "Succes begint waar de anderen opgeven en jij doorzet."</div>

      <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
      - Kan je afleiden welke vakjes van de QR code je moet kleuren?<br/><br/>
      - Heb je de discman al onderzocht?<br/><br/>
      - Hoor je iets speciaals in de liedjes?<br/><br/>

      </div>
     <img src="/bw.gif" style={{width: "60%", marginLeft: "20%"}} alt="gif"/>
    </div>
    if (props.hint === 2)
    return <div style={{backgroundColor: "black", height: "100%",
    overflow: "hidden", color: "white", fontFamily: "Special Elite", fontSize: 24}}>
    <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
    "It always seems impossible until it’s done."</div>

      <div style={{margin: 30,  fontSize: 20, marginTop: 60}}>
      - Ligt de  blauwe pagina in de juiste richting?<br/><br/>
      - Heb je elke foto al vergeleken met de juiste dia?<br/><br/>
      - Heb je de stroken met letters en een cijfer al eens gescheurd en van plaats veranderd?<br/><br/>

      </div>
      <img src="/bw.gif" style={{width: "60%", marginLeft: "20%"}} alt="gif"/>
    </div>
    if (props.hint === 3)
   return <div style={{backgroundColor: "black", height: "100%",
    overflow: "hidden", color: "white", fontFamily: "Special Elite", fontSize: 24}}>
    <div style={{margin: 30,  fontSize: 20, marginTop: 30}}>
    "We can do anything we want to do if we stick to it long enough."</div>

     <div style={{margin: 30,  fontSize: 20, marginTop: 20}}>
      - Zijn jullie er nog niet in geslaagd de QR code te scannen? <br/><a style={{'color':'white'}} href="/snake">Klik hier om de code over te slaan.</a><br/><br/>
      - Heb je de knoppen die je vindt na snake al eens tegelijk ingedrukt?<br/><br/>
      </div>
     <img src="/bw.gif" style={{width: "60%", marginLeft: "20%"}} alt="gif"/>
    </div>
    if (props.hint === 4)
    return <div style={{backgroundColor: "black", height: "100%",
    overflow: "hidden", color: "white", fontFamily: "Special Elite", fontSize: 24}}>
    <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
    "Wie de regenboog wil zien moet de regen trotseren."</div>

      <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
      - Heb je de decoder goed bekeken? <br/><br/>
      - Heb je de gevonden codes via de decoder al ingekleurd op het cijferscherm? <br/><br/>
      </div>
      <img src="/bw.gif" style={{width: "60%", marginLeft: "20%"}} alt="gif"/>
    </div>

}

export default Tijdsmachine;