import "./App.css";
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import dayjs from 'dayjs';
import Admin from "./Admin";
import SnakeGame from "./Snake";
import Planning from "./Planning";
//import Register from "./Register";
//import Reset from "./Reset";
import CalendarOverview from "./CalendarOverview";
import FullCalendar from "./Calendar";
import Examen from "./Examen";
import Tijdsmachine from "./Tijdsmachine";
import { auth, db } from "./firebase";
import { onValue, ref, } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import LabelBottomNavigation from "./BottomMenu"
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { BounceLoader } from 'react-spinners';


function App() {
  const [events1, setEvents1] = useState([]);
  const [events2, setEvents2] = useState([]);
  const [events3, setEvents3] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [userData, setUserData] = useState({});
  const [users, setUsers] = useState({});
  const [stock, setStock] = useState({});
  const [user, loading] = useAuthState(auth);


  useEffect(() => {
    if (loading) return;
    const loadEvents = () => {
      onValue(ref(db, "users/" + user.uid), (snapshot) => {
        const data = snapshot.val();
        console.log(data);
        if (data)
          setUserData(data);

      });
      console.log(dayjs().add(1, 'month').format("YYYY-MM"))
      onValue(ref(db, 'events/' + dayjs().format("YYYY-MM")), (snapshot) => {
        const data = snapshot.val();
        setEvents1(Object.entries(data).map(([key, value]) => value));
      });

      onValue(ref(db, 'events/' + dayjs().add(1, 'month').format("YYYY-MM")), (snapshot) => {
        const data = snapshot.val();
        setEvents2(Object.entries(data).map(([key, value]) => value));
      });

      onValue(ref(db, 'events/' + dayjs().add(2, 'month').format("YYYY-MM")), (snapshot) => {
        const data = snapshot.val();
        setEvents3(Object.entries(data).map(([key, value]) => value));
      });
    }


    if (user)
      loadEvents();
  }, [user, loading])

  useEffect(() => {
    var events = [...events1, ...events2, ...events3];
    if (userData["user-info"]?.type === "admin") {
      onValue(ref(db, 'users'),
        (snapshot) => {
          const data = snapshot.val();
          setUsers(data);
        });
      onValue(ref(db, 'stock'),
        (snapshot) => {
          const data = snapshot.val();
          setStock(data);
        });
    }
    console.log(events);
    console.log(userData["user-info"]?.type);
    if (userData["user-info"]?.type !== "host" && userData["user-info"]?.type !== "admin") {
      setSelectedEvents(events.filter(e => (e.productName.toLowerCase().includes(userData["user-info"]?.type.toLowerCase()) || e.promotionName?.includes("ZEPPELIN"))))
      return;
    }
    setSelectedEvents(events);
  }, [userData, events1, events2, events3]);
  if (window.location.pathname === "/examen1") return <div className="app"><Examen hint={1} /></div>;
  if (window.location.pathname === "/examen2") return <div className="app"><Examen hint={2} /></div>;
  if (window.location.pathname === "/examen3") return <div className="app"><Examen hint={3} /></div>;
  if (window.location.pathname === "/examen4") return <div className="app"><Examen hint={4} /></div>;
    if (window.location.pathname === "/tijdsmachine1") return <div className="app"><Tijdsmachine hint={1} /></div>;
  if (window.location.pathname === "/tijdsmachine2") return <div className="app"><Tijdsmachine hint={2} /></div>;
  if (window.location.pathname === "/tijdsmachine3") return <div className="app"><Tijdsmachine hint={3} /></div>;
  if (window.location.pathname === "/tijdsmachine4") return <div className="app"><Tijdsmachine hint={4} /></div>;
  if (window.location.pathname === "/snake") return <div className="app"><SnakeGame result={false} /></div>;
  if (window.location.pathname === "/snake-highscore") return <div className="app"><SnakeGame result={true} /></div>;




  return (
    <div className="app">
      {loading ? <BounceLoader style={{ position: "absolute", left: "50%", marginLeft: -30, top: "50%", marginTop: -30 }} color="#ff0003" /> : <div>
        <AppBar position="static" style={{ backgroundColor: "#ff0003" }}>
          <Toolbar style={{ textAlign: "center" }}>
            <img src="logo@2x.png" alt="" style={{ height: "50px", margin: "auto" }} />
          </Toolbar>
        </AppBar>
        <Router>
          {user ?
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/list" element={<CalendarOverview user={user} events={selectedEvents} userData={userData} users={users} />} />
              <Route exact path="/calendar" element={<FullCalendar user={user} users={users} events={selectedEvents} userData={userData} badgeColor="#faad14" showPrevious={false} />} />
              <Route exact path="/settings" element={<Admin user={userData} users={users} stock={stock} />} />
              <Route exact path="/planning" element={<Planning user={userData} users={users} />} />
            </Routes> : <Login />}

          {user && <LabelBottomNavigation user={userData} />}
        </Router></div>}
    </div>
  );
}
export default App;