import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Paper from '@mui/material/Paper';
import {useNavigate} from 'react-router-dom';


export default function LabelBottomNavigation(props) {
  const {user} = props;
  const [value, setValue] = React.useState(window.location.pathname);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue); setValue(newValue);
  };

  return (
  <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
    <BottomNavigation style={{marginBottom: 20}} value={value} onChange={handleChange}>
      <BottomNavigationAction
        label="Binnenkort"
        value="/list"
        icon={<AccessTimeIcon />}

      />
      <BottomNavigationAction
        label="Calender"
        value="/calendar"
        icon={<CalendarMonthIcon />}
      />
      {(user["user-info"]?.type === "admin" || user["user-info"]?.type === "host") &&
       <BottomNavigationAction
        label="Planning"
        value="/planning"
        icon={<PermContactCalendarIcon />}
      />}

      <BottomNavigationAction
        label="Instellingen"
        value="/settings"
        icon={<SettingsIcon />}
      />
    </BottomNavigation>
    </Paper>
  );
}