
function Examen(props){
    if (props.hint === 1)
    return <div style={{backgroundColor: "black", height: "100%",
    overflow: "hidden", color: "white", fontFamily: "Special Elite", fontSize: 24}}>
    <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
    "Succes begint waar de anderen opgeven en jij doorzet."</div>

      <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
      - Heb je de cirkel al uitgeknipt op de lijnen?<br/><br/>
      - Heb je het deksel van de koffer al onderzocht?<br/><br/>
      - Heb je de rode strook met letters al uitgeknipt?<br/><br/>

      </div>
     <img src="/bw.gif" style={{width: "60%", marginLeft: "20%"}} alt="gif"/>
    </div>
    if (props.hint === 2)
    return <div style={{backgroundColor: "black", height: "100%",
    overflow: "hidden", color: "white", fontFamily: "Special Elite", fontSize: 24}}>
    <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
    "It always seems impossible until it’s done."</div>

      <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
      - Heb je al gebeld naar de vermelde gsm nummer en/of een mail gestuurd naar het vermelde email adres?<br/><br/>
      - Heb je de rode strook al op het postkaartje gelegd?<br/><br/>
      - Heb je al eens op de kaart gekeken waar de koffer gevonden is?<br/><br/>

      </div>
      <img src="/bw.gif" style={{width: "60%", marginLeft: "20%"}} alt="gif"/>
    </div>
    if (props.hint === 3)
   return <div style={{backgroundColor: "black", height: "100%",
    overflow: "hidden", color: "white", fontFamily: "Special Elite", fontSize: 24}}>
    <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
    "We can do anything we want to do if we stick to it long enough."</div>

     <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
      - Zit er een mail in je spam? Bekijk ook zeker de handtekening in de mail. <br/><a style={{'color':'white'}} href="/mail.png">Klik hier als je geen antwoord-mail onvangen hebt</a><br/><br/>
      - Kan je de verdachte vingerafdruk matchen met de verkregen locatie op het kaartje? (Geboortedatum)<br/><br/>
      - Heb je al geprobeerd van de gekleurde bollen op hetzelfde moment in te drukken? <br/><br/>
      </div>
     <img src="/bw.gif" style={{width: "60%", marginLeft: "20%"}} alt="gif"/>
    </div>
    if (props.hint === 4)
    return <div style={{backgroundColor: "black", height: "100%",
    overflow: "hidden", color: "white", fontFamily: "Special Elite", fontSize: 24}}>
    <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
    "Wie de regenboog wil zien moet de regen trotseren."</div>

      <div style={{margin: 30,  fontSize: 20, marginTop: 70}}>
     - Heb je bij elke module een symbool en cijfer combinatie gevonden? <br/><br/>
      - Heb je met de ponstang de nodige gaten gemaakt op de locatie van de symbool/cijfer locatie? <br/><br/>
      - Heb je het geponste papier op een ander document gelegd om een cijfercombinatie te bekomen?  <br/><br/>
      </div>
      <img src="/bw.gif" style={{width: "60%", marginLeft: "20%"}} alt="gif"/>
    </div>

}

export default Examen;