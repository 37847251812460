import { Badge, Calendar, Timeline, Divider, Tag, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import React, {useState, useEffect, useCallback} from 'react';
import en_GB from 'antd/lib/locale/en_GB';


function FullCalendar(props){
  const {events, showPrevious, userData, users, user} = props;
  const [selected, setSelected] = useState([]);
  const [selectedDay, setSelectedDay] = useState(dayjs());
  console.log(events);
  const getEvents = useCallback((value) =>{
    const dayEvents = [];
    for (var i=0; i < events.length; i++) {
        if(value.format('YYYY-MM-DD') === events[i].startTime.substring(0,10))
            if(events[i].selected === user.uid || userData["user-info"].type !== "host")
            dayEvents.push(events[i])
    }
    return dayEvents;
  }, [events, userData, user]);
  useEffect(() => {
        setSelected(getEvents(selectedDay));
    }, [events, selectedDay, getEvents])

  const dateCellRender = (value) => {
  const show = value > dayjs().add(-1,'day') || showPrevious;
  const dayEvents = getEvents(value);
   if(dayEvents.length > 0 && show)
    return (
          <Badge count={dayEvents.length} color="#faad14"/>
    );
  };
  const dateCellRenderAdmin = (value) => {
  const show = value > dayjs().add(-1,'day') || showPrevious;
  const dayEvents = getEvents(value);
  console.log(dayEvents);

   if(dayEvents.length > 0 && show)
   return <div>{dayEvents.filter(e => !e.selected && !e.productName.includes("Examen")).length === 0 ?
   <Badge count={dayEvents.filter(e => e.selected || e.productName.includes("Examen")).length} color="#52c41a"/>:
   <Badge count={dayEvents.length} color="#faad14"/> }</div>
  };
  const getTime = (event)=>{
    const date = new Date(event?.startTime)
    var time =""
    if (date.getMinutes() < 9){
        time = date.getHours() + ":0" + date.getMinutes()}
    else{
        time = date.getHours() + ":" + date.getMinutes()}
    return time
  }
  if(userData["user-info"]?.type === "admin")
  return <div>
  <ConfigProvider locale={en_GB}>
  <Calendar validRange={[dayjs().add(-1,'day'),dayjs().add(3, 'month')]} dateCellRender={dateCellRenderAdmin} onSelect={(day) => {setSelected(getEvents(day)); setSelectedDay(day)}}  value={selectedDay}/>
  </ConfigProvider>
   <Divider></Divider>
  <div style={{width: "70%", margin: 20}}>
  <Timeline>
     {selected.map(s => <Timeline.Item><b>{getTime(s)}</b>   {s.title} <Tag>{s.productName}</Tag> {s.selected && <Tag color="green">{users[s.selected]["user-info"].name}</Tag>}</Timeline.Item>)}
  </Timeline>
  {selected.length === 0 && <p>Geen games op de planning.</p> }
  </div>
  </div>;
  if(userData["user-info"]?.type === "host") return <div>
  <Calendar dateCellRender={dateCellRender} onSelect={(day) => {setSelected(getEvents(day)); setSelectedDay(day)}} validRange={[dayjs().add(-1,'day'),dayjs().add(3, 'month')]} value={selectedDay}/>
   <Divider></Divider>
  <div style={{width: "70%", margin: 20}}>
  <Timeline>
     {selected.map(s => <Timeline.Item><b>{getTime(s)}</b>   {s.title} <Tag>{s.participants.numbers.reduce((total, p) => total = total + p.number,0)} personen</Tag></Timeline.Item>)}
  </Timeline>
  {selected.length === 0 && <p>Geen games op de planning.</p> }
  </div>
  </div>;
  else return <div>
  <Calendar dateCellRender={dateCellRender} onSelect={(day) => {setSelected(getEvents(day)); setSelectedDay(day)}} validRange={[dayjs().add(-1,'day'),dayjs().add(3, 'month')]} value={selectedDay}/>
   <Divider></Divider>
  <div style={{width: "70%", margin: 20}}>
  <Timeline>
     {selected.map(s => <Timeline.Item><b>{getTime(s)}</b>   {s.title} {s.promotionName?.includes("ZEPPELIN") && <Tag color="red">HAPJE EN DRANKJE</Tag>}</Timeline.Item>)}
  </Timeline>
  {selected.length === 0 && <p>Geen games op de planning.</p> }
  </div>
  </div>;
};
export default FullCalendar;