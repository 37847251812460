import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import { getDatabase, update, ref, onValue } from "firebase/database";
console.log(process.env);
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE,
  authDomain: "thesuspect-23a2c.firebaseapp.com",
  databaseURL: "https://thesuspect-23a2c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "thesuspect-23a2c",
  storageBucket: "thesuspect-23a2c.appspot.com",
  messagingSenderId: "656420940106",
  appId: "1:656420940106:web:83ac2879ee90c2651079b3",
  measurementId: "G-HERGSW1JH0"
};

const app = initializeApp(firebaseConfig);
const db = getDatabase(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    onValue(ref(db, "users/"+ user.uid+ "/user-info"), (snapshot) => {
        const data = snapshot.val();
        if (!data){
            update(ref(db, "users/"+ user.uid + "/user-info"), {
            uid: user.uid,
            name: user.displayName,
            authProvider: "google",
            email: user.email,
            type: "new"
          });
        }
        });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
};
export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  sendPasswordReset,
  logout,
  update
};





