import React from "react";
import { logout, update, db } from "./firebase";
import {Badge, Button, List, Space, InputNumber, Tag} from 'antd';
import { ref } from "firebase/database";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


function Admin(props) {
  const game1 = {"ANTWERPEN": "3252MMYW7718625E96CFF"}
  const game2 = {"ANTWERPEN": "3252E99H4E18C121C7E09"}
  const { user, users, stock } = props;
  console.log(stock);

  const changeType = (id, type) =>{
    if (type.target.value === "DELETE"){
    }
    else{
    update(ref(db, "users/"+id+"/user-info"), {"type": type.target.value});}
  }
  const changeStock = (id, number) => {
  console.log("stock/"+id+"/stock");
  update(ref(db, "stock/"+id), {"stock": number});
  }

  const getDropDown = (id, info) => {
  return <List.Item style={{marginTop: 10, float: "left"}}>
  {info["user-info"].email}
  <FormControl style={{marginLeft: 10}}>
  <Select
  style={{fontSize : 10, margin: 1}}
    id="demo-simple-select"
    value={info["user-info"].type}
    onChange = {(value) => changeType(id, value)}
  >
    <MenuItem value={"admin"}>Admin</MenuItem>
    <MenuItem value={"host"}>Host</MenuItem>
     <MenuItem value={"ANTWERPEN"}>Antwerpen</MenuItem>
     <MenuItem value={"GENT"}>Gent</MenuItem>
     <MenuItem value={"DELETE"}>DELETE</MenuItem>
  </Select>
</FormControl></List.Item>}

  return (
    <div style={{textAlign: "center", marginTop: 50}}>
    <Button type="primary" onClick={() => logout()}>Uitloggen</Button>
    {user["user-info"]?.type === "admin" &&

    <div style={{marginTop: 40}}>
    <h2>Stock</h2>
    <h4>HET EXAMEN</h4>
    {Object.keys(game1).map((key, index) => (
    <div>
    <Tag>{key}</Tag>
    <Badge
        count={stock[game1[key]]?.stock}
        style={{ backgroundColor: '#52c41a', margin: 5}}
      />
      <Button type="primary" style={{margin: 4}} onClick={() => changeStock(game1[key], stock[game1[key]]?.stock+1)}>+</Button>
      <Button type="primary" style={{margin: 4}} onClick={() => changeStock(game1[key], stock[game1[key]]?.stock-1)}>-</Button>
      </div>
    ))}
    <h4>DE TIJDMACHINE</h4>
    {Object.keys(game2).map((key, index) => (
    <div>
    <Tag>{key}</Tag>
    <Badge
        count={stock[game2[key]]?.stock}
        style={{ backgroundColor: '#52c41a', margin: 5}}
      />
      <Button type="primary" style={{margin: 4}} onClick={() => changeStock(game2[key], stock[game2[key]]?.stock+1)}>+</Button>
      <Button type="primary" style={{margin: 4}} onClick={() => changeStock(game2[key], stock[game2[key]]?.stock-1)}>-</Button>
      </div>
    ))}
    <h2>Gebruikers</h2>
    <List style={{marginLeft: 20}}>
    {Object.entries(users).map(([key, value]) => getDropDown(key,value))}
    </List></div>}
    </div>
  );
}
export default Admin;
